.image {
    width: 70%;
    height: auto;

}

.slides {
    align-items: center;
    width: 100%;
}

.image-textwrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.caption {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    padding: 0 10px 10px;
    opacity: 0.7;
    width: 40%;
    height: 100%;
    position: relative;
}

.white-content .caption {
    color: black;
}

.buttonArrow {
    background-color: rgba(0,0,255,0);
    border: none;
}

.arrow {
    color: white;
}

.white-content .arrow {
    color: black;
}