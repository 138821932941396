* {
    box-sizing: border-box;
}

.checkbox {
    opacity: 0;
    position: absolute;
}


.label {
    background-color: #111;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    height: 26px;
    width: 50px;
    transform: scale(1.5);
    border: 1px solid white;
}

.label .ball {
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    left: 1px;
    height: 22px;
    width: 22px;
    transform: translateX(0px);
    transition: transform 0.2s linear;
}

.checkbox:checked + .label .ball {
    transform: translateX(24px);
}


.fa-moon {
    color: #f1c40f;
}

.fa-sun {
    color: #f39c12;
}

.white-content .label {
    background-color: white;
    border: 1px solid black;
}

.white-content .label .ball {
    background-color: black;
}