@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.App {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
}

.top {
    background-color: #000000ff;
    width: 100%;
    margin-bottom: 50px;
}

.titlearea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 30vh;
    width: 100%;
}



.section-title {
    color: white;
    font-size: 1.95rem;
    line-height: 46px;
    font-weight: 500;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-bottom: 20px;
    border-bottom: 1px solid #CDCDCD; /* Combine border-bottom-color and border-bottom-style */
}
.white-content .section-title {
    color: black;
}

.white-content {
    background: white;
}
