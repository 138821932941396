.titleArea-header {
    color: white;
    font-size: 5rem;
    margin: 0;
    padding: 0;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 800;
}

.titleArea-subheader {
    color: #c2c2c2;
    font-size: 2.5REM;
    margin: 0;
    padding: 0;
}
.titleArea-text {
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: #c2c2c2;
    font-size: 18px;
    line-height: 28px;
    opacity: 100%;
    font-weight: 400;
    width: 100%;
    align-self: flex-start;
    margin: 0;
    text-align: center;
}

.white-content .titleArea-text  {
    color: black;
}

.white-content .titleArea-header {
    color: black;
}

.white-content .titleArea-subheader  {
    color: black;
}

.titleArea-box {
    display: grid;
    grid-template-columns: 1fr 308px;
    grid-gap: 20px;
    width: 100%;
}

.titleArea-left {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.titleArea-right {
    flex-shrink: 0; /* Prevent the right element from shrinking */
    width: 308px;
    float:right;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}


.pushable {
    width: 100%;
    align-self: flex-end;
    color: white;
    background: hsla(197, 100%, 45%, 1);
    border: none;
    border-radius: 12px;
    padding: 0;
    cursor: pointer;
}
.front {
    display: block;
    padding: 12px 42px;
    border-radius: 12px;
    background: hsla(197, 100%, 63%, 1);

    background: linear-gradient(90deg, hsla(197, 100%, 63%, 1) 0%, hsla(294, 100%, 55%, 1) 100%);

    background: -moz-linear-gradient(90deg, hsla(197, 100%, 63%, 1) 0%, hsla(294, 100%, 55%, 1) 100%);

    background: -webkit-linear-gradient(90deg, hsla(197, 100%, 63%, 1) 0%, hsla(294, 100%, 55%, 1) 100%);

    overflow: hidden; /* Hide any overflowed content */
    font-size: 16px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: white;
    transform: translateY(-4px);
}

.pushable:active .front {
    transform: translateY(-2px);
}

.pushable:focus:not(:focus-visible) {
    outline: none;
}

.front {
    transition:
            transform
            600ms
            cubic-bezier(.3, .7, .4, 1);
}

.pushable:hover .front {
    transform: translateY(-6px);
    transition:
            transform
            250ms
            cubic-bezier(.3, .7, .4, 1.5);
}

.pushable:active .front {
    transform: translateY(-2px);
    transition: transform 34ms;
}


/* Media query for screens smaller than 768px */
@media (max-width: 767px) {
    .titleArea-header {
        font-size: 2.5rem;
    }

    .titleArea-subheader {
        font-size: 1.25rem;
    }

    .titleArea-text {
        font-size: 14px;
        line-height: 24px;
    }

    .titleArea-box {
        grid-template-columns: 1fr;
    }

    .titleArea-right {
        width: 100%;
        margin-top: 20px;
    }
}